import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { mainnet, metadata } from "./connector/Connecter";

export const IFTContext = createContext();

const projectId = process.env.REACT_APP_PROJECT_ID;
// const mainnet = {
//   chainId: 80001,
//   name: "Mumbai",
//   currency: "MATIC",
//   explorerUrl: "https://etherscan.io",
//   rpcUrl: "https://rpc-mumbai.maticvigil.com",
// };

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: 97,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: "...", // used for the Coinbase SDK
  }),
  chains: [mainnet],
  projectId,
  themeMode: 'light',
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});
function IFTcontext() {
  var { address, isConnected } = useWeb3ModalAccount();
  // address = "0x5b38da6a701c568545dcfcb03fcb875f56beddc4";
  const user_id = localStorage.getItem("conuid")
  const [balance, setBalance] = useState(0);
  const [settings, setsettings] = useState(null);
  const [dbuser, setdbuser] = useState(null);
  const [stakcontract, setstakcontract] = useState(null);
  const [esigner, setesigner] = useState(null);
  const [DBbalance, setDBBalance] = useState(0);

  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect()


  const formatAddress = (address, size = 6) => {
    if (address) {
      return address.substr(0, size) + "..." + address.substr(-size, size);
    }
  };
  const connectInjected = async () => {
    try {
      await open();
    } catch (ex) {
      console.log(ex);
    }
  };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  const getSetting = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };

  async function disconnectNow() {
    try {
      // disconnect()
      localStorage.setItem("isconnected", false);
      localStorage.removeItem("conuid")
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  }

  const connectMetamask = async () => {
    try {
      await open();
      localStorage.setItem("isconnected", true);
    } catch (error) {
      alert(error);
    }
  };

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      u_id: address ? address.toLowerCase() : address,
    });
  };
  const getuserDB = async (userid) => {
    var data = await getudata(userid);

    setdbuser(data.data.data);
    if (data.data.error) {
      return "";
    }
    var dap = data.data.data;
    var fbln =
      dap.staking_rew +
      dap.level_rew +
      dap.ror_rew -
      dap.t_withdrawal;
    setDBBalance(Number(fbln).toFixed(2));


  };
  const getSigner = async () => {
    if (!isConnected) return;

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    setesigner(signer)
  }
  useEffect(() => {
    getuserDB(user_id);
  }, [user_id]);
  useEffect(() => {
    getSetting();
  }, []);
  useEffect(() => {
    getSigner();
  }, [isConnected, walletProvider]);


  const getsmcs = async () => {
    if (!isConnected || !settings.token || esigner === null) return;

    let contract = new ethers.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    let maincontract = new ethers.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    setstakcontract(maincontract);
    if (address) {
      var balance = await contract.balanceOf(address);
      setBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(0))
    }

  };
  function openLevel(investAmount) {
    console.log("investAmount", investAmount);

    const levels = [1, 2, 3, 4];
    const investments = [200, 500, 1000, 2000];

    if (investAmount < investments[0]) {
      return { level: 0, amountNeeded: investments[0] - investAmount };
    }

    for (let i = 0; i < investments.length; i++) {
      if (investAmount < investments[i]) {
        return {
          level: levels[i - 1], // Current level
          amountNeeded: investments[i] - investAmount, // Amount needed for the next level
        };
      }
    }

    // If the investment is at or above the highest threshold
    return {
      level: levels[levels.length - 1], // Highest level
      amountNeeded: 0, // No further levels to achieve
    };

  }
  useEffect(() => {
    getsmcs();
  }, [isConnected, address, walletProvider, settings, esigner]);

  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <IFTContext.Provider
        value={{
          settings: settings,
          balance,
          dbuser,
          getuserDB,
          account: address ? address.toLowerCase() : address,
          user_id,
          connectInjected,
          disconnectNow,
          connectMetamask,
          formatAddress,
          toastError,
          toastSuccess,
          getudata,
          toastInfo,
          getsmcs,
          esigner,
          DBbalance,
          ethers,
          copyaddress,
          openLevel,
          stakcontract,
        }}
      >
        <App />
      </IFTContext.Provider>
    </>
  );
}

export default IFTcontext;
