import React, { useContext, useEffect, useState } from "react";
import Pagination from "../Comman/Pagination";
import axios from "axios";
import { IFTContext } from "../../IFTContext";

export default function PenWithdrawal({ reload }) {
  const { user_id, formatAddress, copyaddress } = useContext(IFTContext);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const getData = async () => {
    if (!user_id) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getpbyid",
        u_id: user_id,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        setisLoading(false);
        console.log("res", res.data);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
    console.log("reload", reload);
  }, [user_id, pages, pageSize, currentPage, reload]);
  return (
    <>
      {data.length ? (
        <div className="pools_table__totalitem overflow-auto ">
          <h4>Pending Withdrawal</h4>
          <hr />
          <table>
            <tbody>
              <tr>
                <th>#</th>
                <th>Amount</th>
                <th>Fee</th>
                <th>Total Recieve</th>
                <th>Datetime</th>
              </tr>
              {data.map((e, i) => {
                return (
                  <tr>
                    <td>{e.id}</td>
                    <td>$ {e.amount}</td>
                    <td>$ {e.fee}</td>
                    <td>$ {e.t_rcv}</td>
                    <td>{e.datetime}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
