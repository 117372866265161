import React from 'react'

export default function Footer() {
    return (
        <>
            <footer className="footer bg5-colo">
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-colo">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className=" roboto text-center text-md-start">Copyright 2024 -
                                        <a href="/" className="text-dark">IFT</a>&nbsp;
                                        All Rights Reserved <span className=" fs-five mx-2">|</span> Design By&nbsp;
                                        <a href="/" className="text-dark roboto">IFT</a>
                                    </span> 
                                </div>
                                {/* <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a href="javascript:void(0)" className=" roboto ps-4 ps-sm-6">
                                        Privacy Policy</a>
                                    <span className=" fs-five">|</span>
                                    <a href="javascript:void(0)" className=" roboto">
                                        Cookie Policy</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}
