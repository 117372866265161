import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IFTContext } from '../../IFTContext';
import axios from "axios";

export default function LoginMain() {
    const {
        toastError,
        toastSuccess,
        getuserDB
    } = useContext(IFTContext);

    const [password, setpassword] = useState("");
    const [email, setemail] = useState("");
    const goto = useNavigate()

    const login = async () => {
        if (!email) {
            toastError("Enter Email");
            return;
        }
        const isValidEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
        if (!isValidEmail(email)) {
            toastError("Enter valid Email");
            return;
        }
        if (!password) {
            toastError("Enter Password");
            return;
        }

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "email",
                submethod: "login",
                email: email,
                password: password,
                key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess('Login')
                localStorage.setItem("conuid", res.data.user_id);
                getuserDB(res.data.user_id)
                window.location.href = ' /dashboard'
            });
    };


    return (
        <>
            <section className="buy_crypt pt-120 pb-120">
                <div className="container pt-17 pt-sm-20 pt-lg-0">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8">
                            <div className="buy_crypto__formarea p-6 p-px-8 rounded-20 bg7-color wow fadeInUp" style={{ border: "2px solid #1062fe" }}>
                                <h2 className="mb-3">Login</h2>
                                <h6 className="mb-3 d-flex">Don't have an account &nbsp;<Link to="/register" className='text-primary'>Register</Link></h6>
                                <div className="buy_crypto__formarea-group mb-5 mb-md-6">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 mt-2">
                                            <label className="mb-2">Email</label>
                                            <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Email"
                                                    className="text-black"
                                                    value={email}
                                                    onChange={(e) =>
                                                        setemail(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12 mt-2">
                                            <label className="mb-2">Password</label>
                                            <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                <input
                                                    type="password"
                                                    placeholder="*****"
                                                    className="text-black"
                                                    value={password}
                                                    onChange={(e) =>
                                                        setpassword(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <h6 className="mb-3 d-flex"> &nbsp;<Link to="/forgot-password" className='text-primary'>Forgot Password</Link></h6>

                                    </div>
                                </div>
                                <button className="cmn-btn w-25 py-3 px-5 px-md-6 d-block" onClick={() => login()}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
