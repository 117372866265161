import React, { useContext, useRef, useState } from 'react';
import { IFTContext } from '../../IFTContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function AddKyc() {
    const {
        dbuser, user_id,
        toastSuccess,
        toastError,
        getuserDB,
    } = useContext(IFTContext);

    const [kycno, setKycno] = useState('');
    const [kycname, setKycname] = useState('');
    const [error, setError] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([null, null]); // Track two files
    const [previewUrls, setPreviewUrls] = useState([null, null]); // Track two preview URLs

    const fileInputRef1 = useRef(null); // Ref for first file input
    const fileInputRef2 = useRef(null); // Ref for second file input
    const navigate = useNavigate();

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const validateFile = (file) => {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxSize = 2 * 1024 * 1024; // 2MB

        if (!allowedTypes.includes(file.type)) {
            setError('Only PNG, JPEG, and JPG files are allowed.');
            return false;
        }

        if (file.size > maxSize) {
            setError('File size should be less than 2MB.');
            return false;
        }

        setError('');
        return true;
    };

    const handleDrop = (event, index) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && validateFile(file)) {
            const newSelectedFiles = [...selectedFiles];
            newSelectedFiles[index] = file;
            setSelectedFiles(newSelectedFiles);

            const newPreviewUrls = [...previewUrls];
            newPreviewUrls[index] = URL.createObjectURL(file);
            setPreviewUrls(newPreviewUrls);
        }
    };

    const handleFileChange = (event, index) => {
        const file = event.target.files[0];
        if (file && validateFile(file)) {
            const newSelectedFiles = [...selectedFiles];
            newSelectedFiles[index] = file;
            setSelectedFiles(newSelectedFiles);

            const newPreviewUrls = [...previewUrls];
            newPreviewUrls[index] = URL.createObjectURL(file);
            setPreviewUrls(newPreviewUrls);
        }
    };

    const handleClick = (index) => {
        if (index === 0) {
            fileInputRef1.current?.click();
        } else {
            fileInputRef2.current?.click();
        }
    };

    const kyc = async () => {
        try {
            const formData = new FormData();
            formData.append('method', 'user');
            formData.append('submethod', 'addkyc');
            formData.append('key', process.env.REACT_APP_KEY);
            formData.append('user_id', user_id);
            formData.append('aadhar_no', kycno);
            formData.append('aadhar_name', kycname);

            // Append both selected files to the form data
            selectedFiles.forEach((file, index) => {
                if (file) {
                    formData.append(`file${index + 1}`, file); // Append with keys file1 and file2
                }
            });

            // Log FormData content for debugging
            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.error) {
                toastError(response.data.error);
            } else {
                toastSuccess(response.data.data);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Error making API call:', error?.response || error);
            toastError('Failed to submit ticket. Please check the console for more details.');
        }
        getuserDB();
    };

    return (
        <>
            <div className="earn_crypto__cardone bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                <h2 className="mb-3 text-center">KYC</h2>
                <div className="earn_crypto__rangeslider">
                    <div className="slidecontainer">
                        <div className="d-flex align-items-center gap-3 justify-content-center flex-wrap mb-5 mb-md-6">
                            {dbuser ? dbuser.kyc_status ?
                                <h3 className='text-success'>Verified</h3> :
                                <div>
                                    <div className="row">
                                        <div className='d-flex align-items-center gap-3 justify-content-between flex-wrap mb-5 mb-md-6'>
                                            <div>
                                                <div
                                                    onClick={() => handleClick(0)}  // Add onClick to trigger file input
                                                    onDragOver={handleDragOver}
                                                    onDrop={(e) => handleDrop(e, 0)}  // Drag & Drop for first image
                                                    style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center', cursor: 'pointer' }}
                                                >
                                                    {previewUrls[0] ? (
                                                        <img src={previewUrls[0]} alt="Preview" style={{ maxWidth: '100px', height: 'auto' }} />
                                                    ) : (
                                                        <p>Click to select or drag the Front Side image here</p>
                                                    )}
                                                </div>
                                                <input
                                                    type="file"
                                                    ref={fileInputRef1}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileChange(e, 0)}
                                                />
                                            </div>

                                            <div>
                                                <div
                                                    onClick={() => handleClick(1)}  // Add onClick to trigger file input
                                                    onDragOver={handleDragOver}
                                                    onDrop={(e) => handleDrop(e, 1)}  // Drag & Drop for second image
                                                    style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center', cursor: 'pointer' }}
                                                >
                                                    {previewUrls[1] ? (
                                                        <img src={previewUrls[1]} alt="Preview" style={{ maxWidth: '100px', height: 'auto' }} />
                                                    ) : (
                                                        <p>Click to select or drag the Back Side image here</p>
                                                    )}
                                                </div>
                                                <input
                                                    type="file"
                                                    ref={fileInputRef2}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileChange(e, 1)}
                                                />
                                            </div>

                                        </div>
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                    </div>
                                    <div className="row">
                                        <div className="form-group style-border2 col-md-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Aadhar Number"
                                                value={kycno}
                                                onChange={(e) => setKycno(e.target.value)}
                                            />
                                            <i className="fal fa-usd" />
                                        </div>
                                        <br />
                                        <br />
                                        <div className="col-12 form-group style-border2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Name as per Aadhar"
                                                value={kycname}
                                                onChange={(e) => setKycname(e.target.value)}
                                            />
                                            <i className="far fa-pencil" />
                                        </div>
                                        <div className="form-btn col-12 my-5">
                                            <button className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1" onClick={kyc}>
                                                Submit <i className="fa-solid fa-arrow-right ms-2" />
                                            </button>
                                        </div>
                                    </div>
                                </div> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
