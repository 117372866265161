import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import Index from "./Components/Home/Index";
import Login from "./Components/login/Login";
import Register from "./Components/Register/Register";
import Forgot from "./Components/forgot/Forgot";
import Reset from "./Components/reset/Reset";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:link_ref_address" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;