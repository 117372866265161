import React from 'react'

export default function Technology() {
    return (
        <>
            <section className="life_digital pt-120 pb-120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <div className="life_digital__title text-start">
                                <h2 className="wow fadeInUp text-white">WHY INFINITY TRADERS IS A TRUSTED CHOICE BY INVESTORS?</h2>
                            </div>
                            <br />
                            <div className="counter_section__area d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap flex-lg-nowrap gap-5 gap-md-10">
                                <div className="counter_section__item">
                                    <p className="text-start text-white">
                                        Infinity Traders is a dynamic platform dedicated to achieving financial success through expert-driven online trading. Our team is composed of highly skilled and experienced traders who comprehend the nuances of the market. We streamline the path to financial goals, providing a seamless experience for our clients. Trust Infinity Traders to navigate the complexities of online trading, ensuring your investment journey is guided by expertise and geared towards success, all facilitated by decentralized solutions and smart contracts
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <img src="./assets/images/why_infinity_traders.png" />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
