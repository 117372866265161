import React from 'react'

export default function Ferture() {
    return (
        <>
            <section className="trust_security pt-120 pb-120">
                <div className="container">
                    <div className="row align-items-end justify-content-between gy-5 gy-md-6 mb-10 mb-md-15">
                        <div className="col-lg-7 col-xxl-6">
                            <div className="trust_security__title wow fadeInUp">
                                <h2>Web3: Elevating Trust and Security in the Digital Landscape</h2>
                                {/* <h2 className="trust_security__title-tstyle">Crypto Exchange</h2> */}
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <p className="wow fadeInUp fs-3">Guard Your Organization with AI-Driven Comprehensive Cybersecurity Excellence</p>
                        </div>
                    </div>
                    <div className="row gy-5 gy-md-6 justify-content-center">
                        <div className="col-lg-8">
                            <div className="row gy-5 gy-md-6 justify-content-center">
                                <div className="col-12">
                                    <div className="position-relative wow fadeInUp">
                                        <img src="assets/images/support227.png" alt="Image" />
                                        <div className="d-flex align-items-center justify-content-between gap-3  position-absolute bottom-0 start-0 ps-5 ps-md-6 pb-8 pb-md-10 pe-3 pe-md-4 w-100 flex-wrap">
                                            <a href="javascript:void(0)">
                                                <h3>ROBUST TRADING TOOLS.</h3>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="position-relative wow fadeInUp">
                                        <img src="assets/images/trade.png" alt="Image" />
                                        <a href="javascript:void(0)">
                                            <h3 className="d-flex align-items-center justify-content-between gap-3  position-absolute top-0 start-0 ps-5 ps-md-6 pt-8 pt-md-10 pe-3 pe-md-4 w-100">
                                                WELL-EXPERIENCED TRAD</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="position-relative wow fadeInUp">
                                        <img src="assets/images/trustedsecure.png" alt="Image" />
                                        <a href="javascript:void(0)">
                                            <h3 className="d-flex align-items-center justify-content-between gap-3  position-absolute top-0 start-0 ps-5 ps-md-6 pt-8 pt-md-10 pe-3 pe-md-4 w-100">
                                                MULTI-PLATFORM TRADING FACILIT</h3>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row gy-5 gy-md-6 justify-content-center">
                                <div className="col-md-6 col-lg-12">
                                    <div className="position-relative wow fadeInUp">
                                        <img src="assets/images/cardtocrypto.png" alt="Image" />
                                        <a href="javascript:void(0)">
                                            <h3 className="position-absolute top-0 start-0 ps-5 ps-md-6 pt-8 pt-md-10 pe-3 pe-md-4 w-100">
                                                FIXED PROFIT YIELD.</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-12">
                                    <div className="position-relative wow fadeInUp">
                                        <img src="assets/images/automatically.png" alt="Image" />
                                        <a href="javascript:void(0)">
                                            <h3 className="position-absolute bottom-0 start-0 ps-5 ps-md-6 pb-8 pb-md-10 pe-3 pe-md-4 w-100">
                                                24/7 CUSTOMER CARE</h3>
                                        </a>
                                        <a href="javascript:void(0)" className="trust_security__button cmn-btn px-3 py-2 position-absolute end-0 top-0 rounded-5 mt-5 mt-md-6 me-5 me-md-6"><i className="ti ti-arrow-up-right fs-four" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
