import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IFTContext } from '../../IFTContext';
import axios from "axios";

export default function ForgotPassword() {
    const {
        toastError,
        toastSuccess,
    } = useContext(IFTContext);

    const [email, setemail] = useState("");

    const login = async () => {
        if (!email) {
            toastError("Enter Email");
            return;
        }
        const isValidEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
        if (!isValidEmail(email)) {
            toastError("Enter valid Email");
            return;
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "email",
                submethod: "forgot",
                email: email,
                key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess(res.data.data)
            });
    };


    return (
        <>
            <section className="buy_crypt pt-120 pb-120">
                <div className="container pt-17 pt-sm-20 pt-lg-0">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8">
                            <div className="buy_crypto__formarea p-6 p-px-8 rounded-20 bg7-color wow fadeInUp" style={{ border: "2px solid #1062fe" }}>
                                <h2 className="mb-3">Forgot Password ?</h2>
                                <h6 className="mb-3 d-flex">Don't worry we'll help you.</h6>
                                <div className="buy_crypto__formarea-group mb-5 mb-md-6">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 mt-2">
                                            <label className="mb-2">Email</label>
                                            <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Email"
                                                    className="text-black"
                                                    value={email}
                                                    onChange={(e) =>
                                                        setemail(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="cmn-btn w-25 py-3 px-5 px-md-6 d-block" onClick={() => login()}>
                                    Send Reset Link
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
