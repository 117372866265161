import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IFTContext } from '../../IFTContext';
import axios from "axios";

export default function ResetPassword() {
    const {
        toastError,
        toastSuccess,
    } = useContext(IFTContext);

    const [password, setpassword] = useState("");
    const [password_n, setpassword_n] = useState("");
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('a');
    const goto = useNavigate()

    const login = async () => {
        if (!password || !password_n) {
            toastError("Enter password");
            return;
        }
        if(password !== password_n){
            toastError("Passwords do not match");
            return;
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "email",
                submethod: "update",
                token: token,
                password: password,
                key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess(res.data.data)
                goto("/login")
            });
    };


    return (
        <>
            <section className="buy_crypt pt-120 pb-120">
                <div className="container pt-17 pt-sm-20 pt-lg-0">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8">
                            <div className="buy_crypto__formarea p-6 p-px-8 rounded-20 bg7-color wow fadeInUp" style={{ border: "2px solid #1062fe" }}>
                                <h2 className="mb-3">Reset Password</h2>
                                <h6 className="mb-3 d-flex">Don't worry we'll help you.</h6>
                                <div className="buy_crypto__formarea-group mb-5 mb-md-6">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 mt-2">
                                            <label className="mb-2">Password</label>
                                            <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Password"
                                                    className="text-black"
                                                    value={password}
                                                    onChange={(e) =>
                                                        setpassword(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12 mt-2">
                                            <label className="mb-2">Retype Password</label>
                                            <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Password"
                                                    className="text-black"
                                                    value={password_n}
                                                    onChange={(e) =>
                                                        setpassword_n(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="cmn-btn w-25 py-3 px-5 px-md-6 d-block" onClick={() => login()}>
                                    Update Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
