import React, { useContext, useEffect, useState } from "react";
import Pagination from "../Comman/Pagination";
import axios from "axios";
import { IFTContext } from "../../IFTContext";

export default function Reward() {
    const { user_id, formatAddress, copyaddress } = useContext(IFTContext);
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const getData = async () => {
      if (!user_id) {
        return;
      }
      setisLoading(true);
  
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "sincome",
          submethod: "getbyid",
          u_id: user_id,
          key: process.env.REACT_APP_KEY,
          page: currentPage,
          pageSize: pageSize,
        })
        .then((res) => {
          setisLoading(false);
          console.log("res", res.data);
          // var dedata = decryptData(res.data);
          if (res.data.error) {
            return "";
          }
          setdata(res.data.data);
          setPages(Math.ceil(res.data.dataLength / pageSize));
        });
    };
    useEffect(() => {
      getData();
    }, [user_id, pages, pageSize, currentPage]);
    return (
      <>
        <div className="pools_table__totalitem overflow-auto">
          <table>
            <tbody>
              <tr>
                <th>#</th>
                <th>Staking</th>
                <th>Reward</th>
                <th>Reward Per</th>
                <th>Flushed</th>
                <th>Datetime</th>
              </tr>
              {data.map((e, i) => {
                return (
                  <tr>
                    <td>{e.id}</td>
                    <td>$ {e.staking}</td>
                    <td>$ {e.income}</td>
                    <td> {e.income_per} %</td>
                    <td> <span  className="p3-color"> {e.flushed?"Flushed":""}</span></td>
                    <td>{e.datetime}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pages={pages}
        />
      </>
    );
  
}
