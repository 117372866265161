import React from 'react'

export default function Faq() {
    return (
        <>
            <section className="rewards_faq pt-120 pb-120 position-relative" id='Faq'>
                <div className="container">
                    <div className="row align-items-center justify-content-center gy-8">
                        <div className="col-xl-6">
                            <div className="rewards_faq__content">
                                <h2 className="mb-5 mb-md-6 wow fadeInUp">FAQs</h2>
                                <p className="roboto mb-8 mb-md-10 wow fadeInUp">Explore our FAQs for fast, informative answers to
                                    frequently
                                    asked questions and common concerns.</p>
                                <div className="accordion-section">
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                1. What is Infinity Traders?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>Infinity Traders is a dynamic platform specializing in online trading, committed to empowering individuals to achieve financial success. Our team of highly skilled and experienced traders guides clients through the complexities of the market. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                2. How does Infinity Traders simplify the path to financial goals?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>We leverage the expertise of our traders to simplify the online trading process. By providing a seamless experience, we aim to make achieving your financial goals more accessible and efficient.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                3. What sets Infinity Traders apart from other platforms?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>Our platform stands out for its team of seasoned traders, deep market understanding, and a commitment to client success. We utilize decentralized solutions and smart contracts for secure and efficient trading.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                4. How can I trust Infinity Traders with my investments?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>Infinity Traders prioritizes transparency and security. We implement advanced technologies and follow industry best practices to safeguard your investments. Our team's experience adds an extra layer of assurance.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                5. How do decentralized solutions and smart contracts play a role?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>Infinity Traders harnesses decentralized solutions and smart contracts to enhance security, transparency, and efficiency in the trading process. This technology ensures trust and reliability in all transactions.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                6. Is there a minimum investment requirement?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>We offer flexibility to cater to different investor needs. While there is $100  minimum, it's advisable to start with an amount that aligns with your financial goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                7. How do I get started with Infinity Traders?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>To begin your financial journey with us, simply sign up on our platform with Wallet connect, Metamask, Trust wallet or any other Web 3 wallet. Once registered, you can explore our services, consult with our team, and start investing based on your preferences and goals..</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                8. Can I withdraw my funds at any time?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>Yes, you can withdraw your funds . Withdrawals and income payouts occur twice monthly.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                9. How can I contact Infinity Traders for further assistance?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>For assistance, contact our customer support team through our website. Our system operates on a fully decentralized model for transparency and efficiency.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="rewards_faq__thumb">
                                <img src="assets/images/faqs.png" className="max-un leftright-animation" alt="Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
