import React from 'react'
import Header2 from '../Comman/Header2'
import Footer from '../Comman/Footer'
import Statstics from './Statstics'
import Navbar from './Navbar'

export default function Dashboard() {
    return (
        <>
            <Header2 />
            {/* <section className="cmn_heros pb-120 pt-120">
                <div className="container">
                    <div className="row justify-content-center mt-5 mt-md-8 mt-lg-0">
                        <div className="col-xxl-6">
                            <div className="cmn_heros__title text-center pt-15 pt-lg-6">
                                <h1 className="display-three mb-5 mb-md-7 wow fadeInUp">Staking Pools</h1>
                                <p className="roboto wow fadeInUp">Earn passive income easily with just one click!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Navbar />
            {/* <Statstics /> */}
            {/* <Footer /> */}
        </>
    )
}
