import React, { useContext, useEffect, useState } from "react";
import Pagination from "../Comman/Pagination";
import axios from "axios";
import { IFTContext } from "../../IFTContext";

export default function Staking() {
  const { user_id, formatAddress, copyaddress } = useContext(IFTContext);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const getData = async () => {
    if (!user_id) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "transaction",
        submethod: "getbyid",
        u_id: user_id,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [user_id, pages, pageSize, currentPage]);
  return (
    <>
      <div className="pools_table__totalitem overflow-auto">
        <table>
          <tbody>
            <tr>
              <th>#</th>
              <th>Hash</th>
              <th>Amount</th>
              <th>Datetime</th>
            </tr>
            {data.map((e, i) => {
              return (
                <tr>
                  <td>{e.id}</td>
                  <td className="p1-color fs-ten">
                    <a
                      className="py-1 px-2 bg1-color rounded-1 p1-color"
                      href={`${process.env.REACT_APP_EXPLORER}tx/${e.hash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatAddress(e.hash)}
                    </a>
                  </td>
                  <td>$ {e.amount}</td>
                  <td>{e.createdAt}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pages={pages}
      />
    </>
  );
}
