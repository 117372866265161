import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AllWithdrawal from "./AllWithdrawal";
import { IFTContext } from "../../IFTContext";
import PenWithdrawal from "./PenWithdrawal";

export default function Witndwaral() {
  const { user_id, toastError, dbuser, DBbalance, getuserDB, toastSuccess } =
    useContext(IFTContext);
  const [reload, setreload] = useState(0);
  const [amt, setamt] = useState(0);
  const [address, setaddress] = useState(null);
  const [fee_d, setfee_d] = useState(0);
  const [t_rcv, sett_rcv] = useState(0);

  const withdraw = async (type) => {
    if (!user_id) {
      toastError("Connect first");
      return;
    }
    if (!dbuser) {
      return;
    }

    if (!amt) {
      toastError("Enter amount to withdraw");
      return;
    }
    if (Number(amt) > DBbalance) {
      toastError("Insufficient Balance");
      return;
    }
    if (Number(amt) < 20) {
      toastError("Minimum 20$ requried");
      return;
    }

    if (!dbuser.status) {
      toastError("Your user_id is not active");
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        u_id: user_id,
        address: address,
        amount: amt,
        token: 0,
        rate: 1,
        fee: fee_d,
        t_rcv: t_rcv
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        // getDataPending();
        
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }
        toastSuccess(res.data.data);
        getuserDB(user_id);
        setreload(amt)
      });
  };
  useEffect(() => {
    var fe = Number(amt) * 5 / 100
    setfee_d(fe)
    sett_rcv(amt - fe)
  }, [amt])
  useEffect(() => {
    if (dbuser !== null) {
      setaddress(dbuser.address)
    }
  }, [dbuser])
  return (
    <>
      <section className="buy_crypt pt-120 pb-120">
        <div className="container pt-17 pt-sm-20 pt-lg-0">
          <div className="row align-items-center">
            <div className="col-xl-8">
              <div
                className="buy_crypto__formarea p-6 p-px-8 rounded-20 bg7-color wow fadeInUp"
                style={{ border: "2px solid #1062fe" }}
              >
                <h2 className="mb-3">Withdrawal</h2>
                {/* <span className="mb-8 mb-md-10">Buy In Seconds</span> */}
                <div className="buy_crypto__formarea-group mb-5 mb-md-6">
                  <div className="d-flex justify-content-between">
                    <label className="mb-2">Withdraw Address</label>
                  </div>
                  <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                    {dbuser !== null ?
                      dbuser.address === null ?
                        <input
                          type="text"
                          placeholder="Enter Address"
                          value={address}
                          onChange={(e) => setaddress(e.target.value)}
                        /> :
                        <input
                          type="text"
                          placeholder="Enter Address"
                          value={address}
                        /> : ''}
                  </div>
                  <div className="d-flex justify-content-between pt-3">
                    <label className="mb-2">Amount</label>
                    <label className="mb-2">Balance: {DBbalance} USDT</label>
                  </div>
                  <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                    <input
                      type="text"
                      placeholder="Enter amount to withdrawal"
                      onChange={(e) => setamt(e.target.value)}
                    />
                  </div>
                  <div class="d-flex align-items-center gap-1 mb-2 mb-md-2 justify-content-between flex-wrap">
                    <span>Fee:</span>
                    <span>{fee_d} USD (5%)</span>
                  </div>
                  <div class="d-flex align-items-center  justify-content-between">
                    <span>Total Recieve:</span>
                    <span>{t_rcv} USD</span>
                  </div>
                </div>
                <button
                  className="cmn-btn py-3 px-5 px-md-6 d-block w-100"
                  onClick={() => withdraw()}
                >
                  Withdrawal
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PenWithdrawal reload={reload} />
      <AllWithdrawal />
    </>
  );
}
