
// export const CHAIN_ID = 137;
export const CHAIN_ID = 80001;



// export const chainData = [
//     {
//         chainId: '0x89',
//         chainName: 'Polygon',
//         rpcUrls: ['https://polygon-pokt.nodies.app	'],
//         blockExplorerUrls: ['https://polygonscan.com'],
//     },
// ]
export const mainnet = {
    chainId: 97,
    name: "BSC Testnet",
    currency: "BNB",
    explorerUrl: "https://testnet.bscscan.com",
    rpcUrl: "https://bsc-testnet.publicnode.com",
  };
// export const mainnet = {
//     chainId: 56,
//     name: "BSC Mainnet",
//     currency: "BNB",
//     explorerUrl: "https://bscscan.com",
//     rpcUrl: "https://bsc-dataseed.binance.org",
//   };
export const metadata = {
  name: "IFT Project",
  description: "IFT Project",
  url: "http://localhost:3000", // origin must match your domain & subdomain
  icons: ["https://stake.zapfinance.org/assets/img/logo.png"],
};
