import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import LoginMain from './LoginMain'

export default function Login() {
    return (
        <>
            <Header />
            <LoginMain />
            <Footer />
        </>
    )
}
