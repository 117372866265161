import React from 'react'

export default function WhyChoose() {
    return (
        <>
            <section className="life_digit pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center pb-10 pb-sm-15 pb-md-20">
                        <div className="col-xl-12">
                            <div className="life_digital__title text-start">
                                <h2 className="wow fadeInUp">WHY CHOOSE US</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="counter_section__area d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap flex-lg-nowrap gap-5 gap-md-10">
                            <div className="counter_section__item">
                                <h5 className='mb-2'>Infinity Traders: Empowering Financial Success</h5>
                                <p className="text-start">
                                    Infinity Traders is a dynamic platform dedicated to facilitating financial success through expert-driven online trading. Our team comprises highly skilled and experienced traders who understand the nuances of the market. We simplify the path to financial goals, offering a seamless experience for our clients. Trust Infinity Traders to navigate the complexities of online trading, ensuring your investment journey is guided by expertise and geared towards success.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
