import React from 'react'

export default function About() {
    return (
        <>
            <section className="rewards_faq pt-120 pb-120 position-relative" id='About'>
                <div className="container">
                    <div className="row align-items-center justify-content-center gy-8">
                        <div className="col-xl-6">
                            <div className="rewards_faq__content">
                                <h2 className="mb-5 mb-md-6 wow fadeInUp">WELCOME TO INFINITY GLOBAL</h2>
                                <p className="roboto mb-8 mb-md-10 wow fadeInUp">At Infinity Traders, we grasp the challenges of online trading and are here to support you. Instead of spending time learning the intricacies, leverage the expertise of our seasoned traders for a hassle-free investment. Allow your funds to work for you in online trading, guided by our professionals, and enjoy a guaranteed profit</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="rewards_faq__thumb">
                                <img src="assets/images/faq-thumb.png" className="max-un leftright-animation" alt="Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <img class="rewards_faq__light position-absolute top-0 start-0" src="assets/images/light-faq.png" alt="Icon"> */}
            </section>
        </>
    )
}
