import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import ForgotPassword from './ForgotPassword'

export default function Forgot() {
    return (
        <>
            <Header />
            <ForgotPassword />
            <Footer />
        </>
    )
}
